<template>
  <div>

    <!-- Filter Section -->
    <FilterReportMonthlyMutation
      title="Filters"
      :baseReportUrl="baseReportUrl"
      @clicked-filter="getReport"
      @on-year-changed="year => yearPeriod = year"
      @on-office-changed="office => officeReportText = office"
    />

    <div class="mt-1 mb-1 d-flex justify-content-end">

      <b-button variant="outline-primary" @click="print">
        <feather-icon icon="FileIcon" />
        {{ $t('globalActions.print') }}
      </b-button>

      <b-button-group class="ml-1">
        <b-dropdown right variant="outline-primary">

          <template #button-content>
            <feather-icon icon="DownloadIcon"/>
            {{ $t('globalActions.export') }}
          </template>

          <b-dropdown-item-button @click="exportToPDF">PDF</b-dropdown-item-button>
          <b-dropdown-item-button @click="exportToExcel">Excel</b-dropdown-item-button>

        </b-dropdown>
      </b-button-group>

    </div>

    <!-- Table Container Card -->
    <b-card no-body id="pdfDom">

        <b-card-body class="text-center">
          <h2>{{ companyName }}</h2>
          <h3>{{ $t('apps.reportMonthlyMutation.singular.monthlyMutationReport') }}</h3>
          <h4 class="mt-sm-1">{{ officeReportText }}</h4>
          <h4 class="mt-sm-1">{{ yearPeriod }}</h4>
        </b-card-body>

        <div v-if="isBusy" class="my-2 text-center text-primary d-flex flex-column justify-content-center">
          <div class="mb-1 text">
            <b-spinner class="align-middle"/>
          </div>
          <strong>{{ $t('globalActions.fetchingDataAPI') }}</strong>
        </div>

        <b-table-simple
          id="monthly-mutation-table"
          :responsive="true"
        >
          <b-thead>
            <b-tr>
              <b-th class="text-center align-middle">Account</b-th>
              <b-th
                v-for="(month, index) in months"
                :key="index"
                class="text-right"
              >
                {{ month.name }}
              </b-th>
            </b-tr>
          </b-thead>
          <b-tbody>
            <b-tr
              v-for="(prop, index) in itemLists"
              :key="prop.coa"
            >
              <b-td
                v-for="column in tableColumns"
                :key="`${column.key}-${index}`"
                :class="column.tdClass"
              >
                <span v-if="Number(prop[column.key]) > 0" class="text-success">{{ Number(prop[column.key]).toLocaleString() }}</span>
                <span v-else-if="Number(prop[column.key]) < 0" class="text-danger">({{ Number(prop[column.key] * -1).toLocaleString() }})</span>
                <span v-else>{{ prop[column.key] }}</span>
              </b-td>
            </b-tr>
          </b-tbody>
          <b-tfoot>
            <b-tr>
              <b-td class="text-right font-weight-bolder font-italic">Total</b-td>
              <b-td
                v-for="(month, index) in months"
                :key="index"
                class="text-right font-weight-bolder font-italic"
              >
                <span v-if="Number(total[month.key]) > 0" class="text-success">{{ Number(total[month.key]).toLocaleString() }}</span>
                <span v-else-if="Number(total[month.key]) < 0" class="text-danger">({{ Number(total[month.key] * -1).toLocaleString() }})</span>
                <span v-else>{{ total[month.key] }}</span>
              </b-td>
            </b-tr>
          </b-tfoot>
        </b-table-simple>

    </b-card>
  </div>
</template>


<script>
import {
  BCard,
  BCardBody,
  BButtonGroup,
  BButton,
  BDropdown,
  BDropdownItemButton,
  BTableSimple,
  BThead,
  BTh,
  BTr,
  BTd,
  BTbody,
  BTfoot,
  BSpinner
} from 'bootstrap-vue'
import FilterReportMonthlyMutation from '@/components/reports/FilterReportMonthlyMutation.vue'

import { computed, ref } from '@vue/composition-api'
import { formatCurrency, unformatNumber } from '@/utils/formatter'
import useListTable from '@/comp-functions/useListTable'
import useFilterReport from '@/comp-functions/useFilterReport'
import moment from 'moment'


export default {
  components: {
    BCard,
    BCardBody,
    BButtonGroup,
    BButton,
    BDropdown,
    BDropdownItemButton,
    // BTable,
    BTableSimple,
    BThead,
    BTh,
    BTr,
    BTd,
    BTbody,
    BTfoot,
    BSpinner,
    FilterReportMonthlyMutation
  },

  computed: {
    companyName () {
      return this.$store.state.user.currentCompany.name
    },

    months() {
      const months = []
      for (let month = 0; month < 12; month++) {
        months.push({
          name: moment().month(month).format('MMMM'),
          key: `amount_${moment().month(month).format('MMM')}`.toLowerCase()
        })
      }

      return months
    }
  },


  setup () {
    // export format
    const formats = ['xlsx', 'csv', 'txt']

    // Table Handlers
    const tableColumns = [
      { key: 'account_name', label: 'COA', thClass:'', tdClass: '' },
      { key: 'amount_jan', label: 'January', thClass:'text-right', tdClass: 'text-right' },
      { key: 'amount_feb', label: 'February', thClass:'text-right', tdClass: 'text-right' },
      { key: 'amount_mar', label: 'March', thClass:'text-right', tdClass: 'text-right' },
      { key: 'amount_apr', label: 'April', thClass:'text-right', tdClass: 'text-right' },
      { key: 'amount_may', label: 'May', thClass:'text-right', tdClass: 'text-right' },
      { key: 'amount_jun', label: 'June', thClass:'text-right', tdClass: 'text-right' },
      { key: 'amount_jul', label: 'July', thClass:'text-right', tdClass: 'text-right' },
      { key: 'amount_aug', label: 'August', thClass:'text-right', tdClass: 'text-right' },
      { key: 'amount_sep', label: 'September', thClass:'text-right', tdClass: 'text-right' },
      { key: 'amount_oct', label: 'October', thClass:'text-right', tdClass: 'text-right' },
      { key: 'amount_nov', label: 'November', thClass:'text-right', tdClass: 'text-right' },
      { key: 'amount_dec', label: 'December', thClass:'text-right', tdClass: 'text-right' },
    ]

    const {
      isBusy,
      itemLists,
      perPage,
      currentPage,
      totalList,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refListTable,
      statusFilter,
      fetchLists
    } = useListTable({ url: false })

    const yearPeriod = ref(0)
    const officeReportText = ref('')


    const total = computed(() => {
      const tempTotal = {}
      tempTotal.amount_jan = itemLists.value.reduce((accumulator, {amount_jan}) => accumulator + (Number(amount_jan) || 0), 0)
      tempTotal.amount_feb = itemLists.value.reduce((accumulator, {amount_feb}) => accumulator + (Number(amount_feb) || 0), 0)
      tempTotal.amount_mar = itemLists.value.reduce((accumulator, {amount_mar}) => accumulator + (Number(amount_mar) || 0), 0)
      tempTotal.amount_apr = itemLists.value.reduce((accumulator, {amount_apr}) => accumulator + (Number(amount_apr) || 0), 0)
      tempTotal.amount_may = itemLists.value.reduce((accumulator, {amount_may}) => accumulator + (Number(amount_may) || 0), 0)
      tempTotal.amount_jun = itemLists.value.reduce((accumulator, {amount_jun}) => accumulator + (Number(amount_jun) || 0), 0)
      tempTotal.amount_jul = itemLists.value.reduce((accumulator, {amount_jul}) => accumulator + (Number(amount_jul) || 0), 0)
      tempTotal.amount_aug = itemLists.value.reduce((accumulator, {amount_aug}) => accumulator + (Number(amount_aug) || 0), 0)
      tempTotal.amount_sep = itemLists.value.reduce((accumulator, {amount_sep}) => accumulator + (Number(amount_sep) || 0), 0)
      tempTotal.amount_oct = itemLists.value.reduce((accumulator, {amount_oct}) => accumulator + (Number(amount_oct) || 0), 0)
      tempTotal.amount_nov = itemLists.value.reduce((accumulator, {amount_nov}) => accumulator + (Number(amount_nov) || 0), 0)
      tempTotal.amount_dec = itemLists.value.reduce((accumulator, {amount_dec}) => accumulator + (Number(amount_dec) || 0), 0)

      return tempTotal
    })

    return {
      total,
      formatCurrency,
      formats,
      tableColumns,
      isBusy,
      itemLists,
      perPage,
      currentPage,
      totalList,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refListTable,
      statusFilter,
      yearPeriod,
      officeReportText,
      ...useFilterReport({
        baseUrl: 'reports/monthly-mutation',
        fetchLists
      })
    }
  },


  methods: {
    print () {
      window.print()
    },

    exportToPDF () {
      this.$nextTick(() => this.getPdf())
    },

    exportToExcel () {
      import('@/vendors/Export2Excel').then(excel => {
        const list = this.itemLists

        // add title
        // const title = [this.$store.state.user.currentCompany.name, this.$t('apps.reportLedger.singular.ledgerReport'), this.yearPeriod]
        const headerTitle = this.tableColumns.map(column => column.label)
        // only take indexData from columnDef,
        const headerVal = this.tableColumns.map(column => column.key)
        const data = this.formatJson(headerVal, list).map((row) => {
          return row.map((cell) => {
            return unformatNumber(cell);
          })
        })

        excel.export_json_to_excel({
          multiHeader: [
            [this.$store.state.user.currentCompany.name, '', '', '', '', '', ''],
            ['Monthly Mutation Report', '', '', '', '', '', ''],
            [this.yearPeriod, '', '', '', '', '', ''],
            ['', '', '', '', '', '', '']
          ],
          header: headerTitle,
          data,
          merges: [
            { s: { c: 0, r: 0 }, e: { c: 6, r: 0 } },
            { s: { c: 0, r: 1 }, e: { c: 6, r: 1 } },
            { s: { c: 0, r: 2 }, e: { c: 6, r: 2 } },
            { s: { c: 0, r: 3 }, e: { c: 6, r: 3 } }
          ],
          filename: `Monthly Mutation Report ${this.yearPeriod}`,
          autoWidth: false,
          bookType: 'xlsx'
        })
      })
    },

    formatJson (filterVal, jsonData) {
      return jsonData.map(v => filterVal.map(j => {
        return v[j]
      }))
    }
  }
}
</script>


<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
  @import '@core/scss/vue/libs/vue-flatpicker.scss';

  .dropdown-menu .dropdown-item {
    width: 100%;
  }

  @media print {
    #app {
      * {
        visibility: hidden;
        margin: 0 !important;
      }

      #pdfDom,
      #pdfDom * {
        visibility: visible;
      }

      #pdfDom {
        top: -400px;
      }

      #monthly-mutation-table tr th,
      #monthly-mutation-table tr td {
        padding-left: 0;
        padding-right: 0;
      }

      #monthly-mutation-table tr td {
        padding-top: 0;
        padding-bottom: 0;
      }
    }
  }

  @page {
    size: auto;
    margin-left: 0;
    margin-right: 0;
  }
</style>
