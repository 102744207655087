<template>
  <b-card title="Filters" class="mb-0">
    <b-form @submit.prevent>
      <b-row>
        <!-- Period -->
        <b-col cols="12" md="2">
          <b-form-group
            :label="$t('apps.reportMonthlyMutation.singular.year')"
            label-for="period"
          >
            <v-select
              inputId="period"
              class="select-size-sm"
              v-model="form.year"
              :options="yearList"
            />
          </b-form-group>
        </b-col>

        <!-- Office Id -->
        <b-col cols="12" md="5">
          <b-form-group
            :label="$t('globalSingular.branch')"
            label-for="office"
          >
            <v-select
              class="select-size-sm"
              v-model="form.officeId"
              :options="LOV.offices"
              :reduce="field => field.id"
              itemid="id"
              label="label"
            />
          </b-form-group>
        </b-col>

        <!-- Coa -->
        <b-col cols="12" md="5">
          <b-form-group
            :label="$t('apps.reportBudget.singular.coa')"
            label-for="coa"
          >
            <v-select
                inputId="coa"
                class="select-size-sm"
                v-model="form.coa"
                :options="LOV.coaList"
                :reduce="field => field.account_number"
                itemid="account_number"
                label="label"
              />
          </b-form-group>
        </b-col>

        <!-- Button Filter -->
        <b-col cols="12" md="2">
          <b-button block variant="primary" @click="generateEndpointAPI" class="mt-2 pd-1">
            {{ $t('globalActions.find') }}
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </b-card>
</template>


<script>
import {
  BCard,
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BButton
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { onMounted, ref } from '@vue/composition-api'
import useLocalization from '@/comp-functions/utils/useLocalization'
import useHttp from '@/comp-functions/useHttp'
import storestate from '@/store/index'
import moment from 'moment';


export default {
  props: {
    title: {
      type: String,
      required: true
    },
    baseReportUrl: {
      type: String,
      required: true
    }
  },


  components: {
    BCard,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BButton,
    vSelect
  },


  setup (props, { emit }) {
    const { $t } = useLocalization()
    const { $get } = useHttp()
    const selectedOffice = ref({
      id: null,
      name: ''
    })
    const form = ref({
      officeId: storestate.state.user.companies[0].office.id,
      year: moment().year(),
      coa: null,
    })

    const generatedUrl = (
      officeId = form.value.officeId,
      year = form.value.year,
      coa = form.value.coa
    ) => {
      let queryParams = `${props.baseReportUrl}?`
      queryParams += year ? `&year=${year}` : ''
      queryParams += officeId ? `&office_id=${officeId}` : ''
      queryParams += coa ? `&account_number=${coa}` : ''
      return queryParams
    }

    const LOV = ref({
      offices: [],
      coaList:[],
    })

    const handleOffice = (val) => { selectedOffice.value = {id: val.id, name: val.name} }

    const getOffice = async () => {
      const { data } = await $get({ url: 'master/offices'})
      LOV.value.offices = data
    }

    const getCoa = async () => {
      const { data } = await $get({ url: 'value/getCoa/coaLv2'})
      LOV.value.coaList = data
    }

    const updateOfficeText = () => selectedOffice.value.id
      ? `${$t('globalSingular.office')} ${selectedOffice.value.name}`
      : $t('globalSingular.allOffice')

    const generateEndpointAPI = () => {
      onYearChanged()
      onOfficeChanged()
      emit('clicked-filter', {
        generatedUrl: generatedUrl(),
        officeText: updateOfficeText()
      })
    }

    const onYearChanged = () => {
      emit('on-year-changed', form.value.year)
    }

    const onOfficeChanged = () => {
      const office = LOV.value.offices.filter(item => item.id === form.value.officeId)
      handleOffice(office[0] ? office[0] : { id: null, name: null })
      emit('on-office-changed', updateOfficeText())
    }

    onMounted(async () => {
      await getOffice()
      await getCoa()
      generateEndpointAPI()
    })

    return {
      form,
      LOV,
      handleOffice,
      generateEndpointAPI,
      onYearChanged,
      onOfficeChanged
    }
  },


  computed: {
    yearList () {
      const currentYear = moment().year()
      const years = []

      for (let year = currentYear; year >= currentYear - 10; year--) {
        years.push(year)
      }

      return years
    }
  }
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.select-size-md .vs__dropdown-toggle {
  height: 39px;
}
</style>
